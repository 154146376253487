<template>
  <b-row class="mb-3 d-block">
    <b-overlay :show="loading">
      <AdaptiveCardGroup class="mb-2" :cards="[
          {
            title: 'Количество записей',
            value: formatNumber(this.count),
          },
          {
            title: 'Количество лайков',
            value: formatNumber(this.likes),
          },
          {
            title: 'Количество репостов',
            value: formatNumber(this.reposts),
          },
          {
            title: 'Количество комментариев',
            value: formatNumber(this.comments),
          },
        ]"/>
    </b-overlay>
  </b-row>
</template>

<script>
import AdaptiveCardGroup from '@/views/pages/dashboard/components/GroupStats/AdaptiveCardGroup'
import {BRow, BOverlay} from 'bootstrap-vue'
import {formatNumber} from '@/helpers/format'

export default {
  components: {
    BRow,
    BOverlay,
    AdaptiveCardGroup,
  },

  props: {
    count: Number,
    likes: Number,
    reposts: Number,
    comments: Number,
    loading: Boolean,
  },

  methods: {
    formatNumber,
  },
}
</script>
