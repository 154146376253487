var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mb-3 d-block"},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('AdaptiveCardGroup',{staticClass:"mb-2",attrs:{"cards":[
        {
          title: 'Количество записей',
          value: _vm.formatNumber(this.count),
        },
        {
          title: 'Количество лайков',
          value: _vm.formatNumber(this.likes),
        },
        {
          title: 'Количество репостов',
          value: _vm.formatNumber(this.reposts),
        },
        {
          title: 'Количество комментариев',
          value: _vm.formatNumber(this.comments),
        } ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }