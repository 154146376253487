<template>
  <b-row>
    <b-card no-body class="main-card w-100">
        <b-card-img :src="photoURL" alt="Изображение группы" class="rounded-1 m-1" />
        <b-card-body :title="title">
          <b-card-sub-title>
            <a :href="link" target="_blank">Ссылка на сообщество</a>
          </b-card-sub-title>
          <hr/>
          <b-card-text class="text-justify" v-html="formatDescription(description)" />
        </b-card-body>
    </b-card>
  </b-row>
</template>

<script>
import {
  BRow,
  BLink,
  BCard,
  BCardImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BLink,
    BCard,
    BCardImg,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardText,
  },

  props: {
    title: String,
    description: String,
    link: String,
    photoURL: String,
  },

  methods: {
    formatDescription(description) {
      return description.replaceAll('\n', '<br/>')
    },
  },
}
</script>

<style lang="scss" scoped>
.main-card {
  display: flex;
  flex-direction: row;

  > img {
    max-width: 200px;
    max-height: 200px;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    > img {
      margin: 0 auto !important;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .card-title {
      text-align: center;
    }

    .card-subtitle {
      text-align: center;
    }
  }
}
</style>
