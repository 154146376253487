<template>
  <b-row class="mb-3 d-block">
    <b-overlay :show="loading">
      <AdaptiveCardGroup class="mb-2" :cards="[
          {
            title: 'Количество видео',
            value: formatNumber(this.count),
          },
          {
            title: 'Количество лайков',
            value: formatNumber(this.likes),
          },
          {
            title: 'Количество репостов',
            value: formatNumber(this.reposts),
          },
          {
            title: 'Количество комментариев',
            value: formatNumber(this.comments),
          },
        ]"/>
      <AdaptiveCardGroup :cards="[
          {
            title: 'Количество просмотров',
            value: formatNumber(this.views),
          },
          {
            title: 'Среднее количество просмотров',
            value: formatNumber(this.averageViews),
          },
          {
            title: 'Продолжительность видео',
            value: formatDuration(this.duration),
          },
          {
            title: 'Средняя продолжительность',
            value: formatDuration(this.averageDuration),
          },
        ]"/>
    </b-overlay>
  </b-row>
</template>

<script>
import AdaptiveCardGroup from '@/views/pages/dashboard/components/GroupStats/AdaptiveCardGroup'
import {BRow, BOverlay} from 'bootstrap-vue'
import {formatDuration, formatNumber} from '@/helpers/format'

export default {
  components: {
    BRow,
    BOverlay,
    AdaptiveCardGroup,
  },

  props: {
    count: Number,
    views: Number,
    averageViews: Number,
    likes: Number,
    reposts: Number,
    comments: Number,
    duration: Number,
    averageDuration: Number,
    loading: Boolean,
  },

  methods: {
    formatDuration,
    formatNumber,
  },
}
</script>
