<template>
  <b-row>
    <b-overlay :show="loading" class="w-100">
      <b-card>
        <b-table
            hover
            responsive
            class="position-relative"
            :items="items"
            :fields="fields"
            :sort-by.sync="sort.field"
            :sort-desc.sync="sort.desc"
            no-local-sorting
            show-empty
            empty-text="Нет ни одного репоста"
            @sort-changed="changeSort"
          >
            <template #cell(actorName)="data">
              <a :href="data.item.link" target="_blank">{{data.item.actorName}}</a>
            </template>
            <template #cell(repostedAt)="data">
              {{formatDate(data.item.repostedAt)}}
            </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0 px-1 pb-1">
          <!-- page length -->
          <b-form-group
            label="Кол-во на странице"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1 pagination-options"
          >
            <b-form-select
              id="perPageSelect"
              size="sm"
              inline
              :value="paging.itemsPerPage"
              :options="pageOptions"
              @change="changeItemsPerPage"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              first-number
              last-number
              :value="paging.currentPage || 1"
              :total-rows="paging.totalItems"
              :per-page="paging.itemsPerPage"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </b-row>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BBadge, BContainer,
  BButton, BTable, BPagination,
  BModal, VBModal,
  BForm, BFormGroup, BFormSelect, BFormInput, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { formatDate, formatDuration, formatNumber } from '@/helpers/format'
import debounce from 'lodash.debounce'

export default {
  components: {
    BCard, BCardBody, BRow, BCol, BBadge, BContainer,
    BButton, BTable, BPagination,
    BModal, VBModal,
    BForm, BFormGroup, BFormSelect, BFormInput, BInputGroup, BInputGroupAppend,
    BDropdown, BDropdownItem, BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    postId: Number,
  },

  data() {
    return {
      fields: [
        { key: 'actorName', label: 'ФИО или название', sortable: false },
        { key: 'repostedAt', label: 'Дата репоста', sortable: true },
      ],
      sort: {
        field: 'repostedAt',
        desc: true,
      },
      pageOptions: [5, 10, 15, 25, 50],
    }
  },

  computed: {
    items() {
      return this.$store.state.vk.reposts.items
    },
    paging() {
      return this.$store.state.vk.reposts.paging
    },
    loading() {
      return this.$store.state.vk.loadings.reposts
    },
  },

  methods: {
    formatDate: date => formatDate(date, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }),

    changeItemsPerPage(itemsPerPage) {
      this.$store.dispatch("vk/getReposts", {
        postId: this.postId,
        sort: {
          field: this.sort.field,
          direction: this.sort.desc ? "desc" : "asc",
        },
        paging: {
          page: this.paging.currentPage,
          itemsPerPage,
        },
      })
    },

    changePage(page) {
      this.$store.dispatch("vk/getReposts", {
        postId: this.postId,
        sort: {
          field: this.sort.field,
          direction: this.sort.desc ? "desc" : "asc",
        },
        paging: {
          page,
          itemsPerPage: this.paging.itemsPerPage,
        },
      })
    },

    changeSort(ctx) {
      this.$store.dispatch("vk/getReposts", {
        postId: this.postId,
        sort: {
          field: ctx.sortBy,
          direction: ctx.sortDesc ? "desc" : "asc",
        },
        paging: {
          page: this.paging.page,
          itemsPerPage: this.paging.itemsPerPage,
        },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagination-options label {
    max-width: max-content;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .button-holder {
    justify-content: flex-end;

    @media (max-width: 580px) {
      justify-content: center !important;
      margin-top: 1rem;

      button {
        width: 100%;
      }
    }
  }
</style>
