<template>
  <GroupStats
      :groupStats="groupStats"
      :from="from"
      :to="to"
      :loadings="loadings"
      :groupVideosStats="groupVideosStats"
      :uploads="uploads"
      :views="views"
      :videos="videos"
      :videosOptions="videosOptions"
      :groupPostsStats="groupPostsStats"
      :publish="publish"
      :totalReach="totalReach"
      :posts="posts"
      :postsOptions="postsOptions"
      @back="back"
      @from-changed="fromChanged"
      @to-changed="toChanged"
      @only-with-video-changed="onlyWithVideoChanged"
      @request-videos="requestVideos"
      @request-posts="requestPosts"
      @download-videos-xls="downloadVideosXLS"
      @download-posts-xls="downloadPostsXLS"
      v-if="hasStats"
  />

  <Form
    :searchError="searchError"
    :from="from"
    :to="to"
    @select="select"
    v-else
  />
</template>

<script>
import GroupStats from '@/views/pages/dashboard/components/GroupStats'
import Form from '@/views/pages/dashboard/components/Form'
import {$themeColors} from '@themeConfig'
import {formatDate, formatNumber} from '@/helpers/format'
import debounce from 'lodash.debounce'
import {filesEndpoint} from '@/consts'

export default {
  components: {
    GroupStats,
    Form,
  },

  data() {
    const currentYear = (new Date()).getFullYear()

    const from = new Date(currentYear, 0, 1)
    const to = new Date(currentYear,12,0)

    const formatDate = (d) => {
      let month = d.getMonth() + 1
      if (month < 10) {
        month = `0${month}`
      }

      let day = d.getDate()
      if (day < 10) {
        day = `0${day}`
      }

      return `${d.getFullYear()}-${month}-${day}`
    }

    return {
      from: formatDate(from),
      to: formatDate(to),
      videosOptions: {
        filter: {
          name: "",
        },
        sort: {
          field: "uploadedAt",
          desc: true,
        },
        paging: {
          page: 1,
          itemsPerPage: 25,
        },
      },
      postsOptions: {
        filter: {
          title: "",
          onlyWithVideo: false,
        },
        sort: {
          field: "publishedAt",
          desc: true,
        },
        paging: {
          page: 1,
          itemsPerPage: 25,
        },
      },
      searchError: "",
    }
  },

  computed: {
    groupStats() {
      return this.$store.state.vk.group
    },
    groupVideosStats() {
      return this.$store.state.vk.groupVideosStats || {
        count: 0,
        views: 0,
        averageViews: 0,
        likes: 0,
        reposts: 0,
        comments: 0,
        duration: 0,
        averageDuration: 0,
      }
    },
    groupPostsStats() {
      return this.$store.state.vk.groupPostsStats || {
        count: 0,
        likes: 0,
        reposts: 0,
        comments: 0,
      }
    },
    hasStats() {
      return this.$store.state.vk.group != null
    },
    uploads() {
      return createGraphOptions("uploads", this.$store.state.vk.groupVideosUploadsGraph)
    },
    views() {
      return createGraphOptions("views", this.$store.state.vk.groupVideosViewsGraph)
    },
    videos() {
      return this.$store.state.vk.videos
    },
    loadings() {
      return this.$store.state.vk.loadings
    },
    posts() {
      return this.$store.state.vk.posts
    },
    publish() {
      return createGraphOptions("publish", this.$store.state.vk.groupPostsPublishGraph)
    },
    totalReach() {
      return createGraphOptions("total-reach", this.$store.state.vk.groupPostsTotalReachGraph)
    },
  },

  methods: {
    async select(screenName, from, to) {
      try {
        await this.$store.dispatch("vk/getGroup", {screenName, from, to})
      } catch (error) {
        this.searchError = error.message
      }
    },

    back() {
      this.$store.dispatch("vk/clearGroup")
    },

    fromChanged(ymd) {
      this.from = ymd
      this.$store.dispatch("vk/getVideosForPeriod", {
        from: this.from,
        to: this.to,
      })
      this.$store.dispatch("vk/getPostsForPeriod", {
        from: this.from,
        to: this.to,
        filter: this.postsOptions.filter,
        sort: {
          field: this.postsOptions.sort.field,
          direction: this.postsOptions.sort.desc ? "desc" : "asc",
        },
        paging: this.postsOptions.paging,
      })
    },

    toChanged(ymd) {
      this.to = ymd
      this.$store.dispatch("vk/getVideosForPeriod", {
        from: this.from,
        to: this.to,
      })
      this.$store.dispatch("vk/getPostsForPeriod", {
        from: this.from,
        to: this.to,
        filter: this.postsOptions.filter,
        sort: {
          field: this.postsOptions.sort.field,
          direction: this.postsOptions.sort.desc ? "desc" : "asc",
        },
        paging: this.postsOptions.paging,
      })
    },

    onlyWithVideoChanged(value) {
      this.postsOptions.filter.onlyWithVideo = value
      this.$store.dispatch("vk/getVideosForPeriod", {
        from: this.from,
        to: this.to,
      })
      this.$store.dispatch("vk/getPostsForPeriod", {
        from: this.from,
        to: this.to,
        filter: this.postsOptions.filter,
        sort: {
          field: this.postsOptions.sort.field,
          direction: this.postsOptions.sort.desc ? "desc" : "asc",
        },
        paging: this.postsOptions.paging,
      })
    },

    requestVideos(options) {
      this.videosOptions = options

      const payload = {
        from: this.from,
        to: this.to,
        filter: this.videosOptions.filter,
        sort: {
          field: this.videosOptions.sort.field,
          direction: this.videosOptions.sort.desc ? "desc" : "asc",
        },
        paging: this.videosOptions.paging,
      }

      this.$store.dispatch("vk/getVideos", payload)
    },

    requestPosts(options) {
      this.postsOptions = options

      const payload = {
        from: this.from,
        to: this.to,
        filter: this.postsOptions.filter,
        sort: {
          field: this.postsOptions.sort.field,
          direction: this.postsOptions.sort.desc ? "desc" : "asc",
        },
        paging: this.postsOptions.paging,
      }

      this.$store.dispatch("vk/getPosts", payload)
    },

    formatDate: formatDate,

    downloadVideosXLS() {
      this.downloadXLS("videos")
    },

    downloadPostsXLS() {
      this.downloadXLS("posts")
    },

    downloadXLS(type) {
      const groupID = this.$store.state.vk.group.id
      const url = `${filesEndpoint}/xls?group_id=${groupID}&from=${this.from}&to=${this.to}&type=${type}`
      downloadUrl(url)
    },
  },

  beforeCreate() {
    this.$store.dispatch("users/fetchCurrent")
  }
}

const downloadUrl = (url) => {
  const a = document.createElement('a')

  a.href = url
  a.target = '_blank'
  a.download = 'download'

  const clickHandler = () => {
    setTimeout(() => {
      a.removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)

  a.click()

  return a
}

const createGraphOptions = (id, dstats) => ({
  series: [
    {
      data: dstats.map((v) => v.value),
    },
  ],
  chartOptions: {
    chart: {
      id: 'totalReach',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
      strokeColors: [$themeColors.light],
      colors: [$themeColors.warning],
    },
    colors: [$themeColors.warning],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom(data) {
        return `${'<div class="px-1 py-50"><span>'}${
            formatNumber(data.series[data.seriesIndex][data.dataPointIndex])
        }</span></div>`
      },
    },
    xaxis: {
      categories: dstats.map((v) => formatDate(v.date)),
    },
    yaxis: {
      labels: {
        formatter: value => formatNumber(value),
      }
    },
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
