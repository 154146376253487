<template>
  <b-row>
    <b-overlay :show="loading" class="w-100">
      <b-card no-body>
        <b-container>
          <b-row class="mt-2 mr-1 mb-2 ml-1">
            <b-col cols="12" sm="4">
              <b-form-group
                label-for="filterInput"
                class="mb-0"
              >
                <b-form-input
                  id="filterInput"
                  type="search"
                  placeholder="Поиск по названию"
                  :value="videosOptions.filter.name"
                  @input="changeFilter"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4" class="offset-sm-4">
              <div class="d-flex button-holder">
                <b-button @click="requestXLS">
                  Выгрузить в xls
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-table
            hover
            responsive
            class="position-relative"
            :items="videos.items"
            :fields="fields"
            :sort-by.sync="videosOptions.sort.field"
            :sort-desc.sync="videosOptions.sort.desc"
            no-local-sorting
            show-empty
            empty-text="Нет ни одного видео за выбранный промежуток"
            @sort-changed="changeSort"
          >
            <template #cell(name)="data">
              <a :href="data.item.link" target="_blank">{{ data.item.name }}</a>
            </template>
            <template #cell(uploadedAt)="data">
              {{formatDate(data.item.uploadedAt)}}
            </template>
            <template #cell(duration)="data">
              {{formatDuration(data.item.duration)}}
            </template>
            <template #cell(counters.views)="data">
              {{formatNumber(data.item.counters.views)}}
            </template>
            <template #cell(counters.likes)="data">
              {{formatNumber(data.item.counters.likes)}}
            </template>
            <template #cell(counters.reposts)="data">
              {{formatNumber(data.item.counters.reposts)}}
            </template>
            <template #cell(counters.comments)="data">
              {{formatNumber(data.item.counters.comments)}}
            </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0 px-1 pb-1">

          <!-- page length -->
          <b-form-group
            label="Кол-во на странице"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1 pagination-options"
          >
            <b-form-select
              id="perPageSelect"
              size="sm"
              inline
              :value="videosOptions.paging.itemsPerPage"
              :options="pageOptions"
              @change="changeItemsPerPage"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              first-number
              last-number
              :value="videos.paging.currentPage || 1"
              :total-rows="videos.paging.totalItems"
              :per-page="videos.paging.itemsPerPage"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </b-row>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BBadge, BContainer,
  BButton, BTable, BPagination,
  BModal, VBModal,
  BForm, BFormGroup, BFormSelect, BFormInput, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { formatDate, formatDuration, formatNumber } from '@/helpers/format'
import debounce from 'lodash.debounce'

export default {
  components: {
    BCard, BCardBody, BRow, BCol, BBadge, BContainer,
    BButton, BTable, BPagination,
    BModal, VBModal,
    BForm, BFormGroup, BFormSelect, BFormInput, BInputGroup, BInputGroupAppend,
    BDropdown, BDropdownItem, BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    videos: Object,
    videosOptions: Object,
    loading: Boolean,
  },

  data() {
    return {
      fields: [
        { key: 'name', label: 'Название видео', sortable: false },
        { key: 'uploadedAt', label: 'Дата загрузки', sortable: true },
        { key: 'duration', label: 'Длительность', sortable: false },
        { key: 'counters.views', label: 'Кол-во просмотров', tdClass: 'text-right', sortable: true },
        { key: 'counters.likes', label: 'Кол-во лайков', tdClass: 'text-right', sortable: true },
        { key: 'counters.reposts', label: 'Кол-во репостов', tdClass: 'text-right', sortable: true },
        { key: 'counters.comments', label: 'Кол-во комментариев', tdClass: 'text-right', sortable: true },
        { key: 'platform', label: 'Платформа', sortable: false },
      ],
      pageOptions: [5, 10, 15, 25, 50],
    }
  },

  methods: {
    formatDate: date => formatDate(date, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }),

    formatDuration,
    formatNumber,

    changeItemsPerPage(itemsPerPage) {
      this.$emit("request-videos", {
        ...this.videosOptions,
        paging: {
          ...this.videosOptions.paging,
          itemsPerPage,
        }
      })
    },

    changePage(page) {
      this.$emit("request-videos", {
        ...this.videosOptions,
        paging: {
          ...this.videosOptions.paging,
          page,
        }
      })
    },

    changeFilter: debounce(function (filter) {
      this.$emit("request-videos", {
        ...this.videosOptions,
        filter: {
          ...this.videosOptions.filter,
          name: filter,
        }
      })
    }, 500),

    changeSort(ctx) {
      let field = "uploadedAt"
      switch (ctx.sortBy) {
        case "uploadedAt":
          field = "uploadedAt"
          break

        case "counters.views":
          field = "numberOfViews"
          break

        case "counters.likes":
          field = "numberOfLikes"
          break

        case "counters.reposts":
          field = "numberOfReposts"
          break

        case "counters.comments":
          field = "numberOfComments"
          break

      }

      this.$emit("request-videos", {
        ...this.videosOptions,
        sort: {
          ...this.videosOptions.sort,
          field,
          desc: ctx.sortDesc,
        }
      })
    },

    requestXLS() {
      this.$emit("download-xls")
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagination-options label {
    max-width: max-content;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .button-holder {
    justify-content: flex-end;

    @media (max-width: 580px) {
      justify-content: center !important;
      margin-top: 1rem;

      button {
        width: 100%;
      }
    }
  }
</style>
