import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetAccountConnectURL {
  users {
    getAccountConnectURL
  }
}`

async function getAccountConnectURL() {
  return makeQuery({
    query,
    success: data => data.users.getAccountConnectURL,
  })
}

export default getAccountConnectURL
