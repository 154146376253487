var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"d-block"},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('AdaptiveCardGroup',{attrs:{"cards":[
        {
          title: 'Количество подписчиков',
          value: _vm.formatNumber(this.members),
        },
        {
          title: 'Количество записей',
          value: _vm.formatNumber(this.wallRecords),
        },
        {
          title: 'Количество видео',
          value: _vm.formatNumber(this.videos),
        },
        {
          title: 'Количество статей',
          value: _vm.formatNumber(this.articles),
        } ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }