<template>
  <div>
    <TotalPostsStats
        :count="groupPostsStats.count"
        :likes="groupPostsStats.likes"
        :reposts="groupPostsStats.reposts"
        :comments="groupPostsStats.comments"
        :loading="loadings.all"
    />

    <Chart
        title="Публикации"
        :data="publish"
        :loading="loadings.all"
    />

    <Chart
        title="Полный охват"
        :data="totalReach"
        :loading="loadings.all"
    />

    <PostsList
        :posts="posts"
        :options="postsOptions"
        :loading="loadings.all || loadings.posts"
        @request-posts="$emit('request-posts', $event)"
        @download-xls="$emit('download-xls', $event)"
    />
  </div>
</template>

<script>
import TotalPostsStats from '@/views/pages/dashboard/components/GroupStats/PostsTab/TotalPostsStats'
import Chart from '@/views/pages/dashboard/components/GroupStats/Chart'
import PostsList from '@/views/pages/dashboard/components/GroupStats/PostsTab/PostsList'

export default {
  components: {
    TotalPostsStats,
    Chart,
    PostsList,
  },

  props: {
    from: String,
    to: String,
    groupPostsStats: Object,
    loadings: Object,
    publish: Object,
    totalReach: Object,
    posts: Object,
    postsOptions: Object,
  },
}
</script>
