<template>
  <b-row>
    <b-overlay :show="loading" class="w-100">
      <b-card class="w-100" no-body>
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              {{ title }}
            </b-card-title>
          </div>
          <!--/ title and subtitle -->
        </b-card-header>

        <b-card-body>
          <vue-apex-charts
            type="line"
            height="400"
            :options="data.chartOptions"
            :series="data.series"
            :key="key"
          />
        </b-card-body>
      </b-card>
    </b-overlay>
  </b-row>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
  },
  props: {
    title: String,
    data: {
      chartOptions: Object,
      series: Object,
    },
    loading: Boolean,
  },
  data() {
    return {
      key: 0,
    }
  },
  watch: {
    loading() {
      window.setTimeout(() => this.key++, 400)
    },
  },
}
</script>
