<template>
  <b-row>
    <b-overlay :show="loading" class="w-100">
      <b-card no-body>
        <b-container>
          <b-row class="mt-2 mr-1 mb-2 ml-1">
            <b-col cols="12" sm="4">
              <b-form-group
                label-for="filterInput"
                class="mb-0"
              >
                <b-form-input
                  id="filterInput"
                  type="search"
                  placeholder="Поиск по названию"
                  :value="options.filter.title"
                  @input="changeFilter"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4" class="offset-sm-4">
              <div class="d-flex button-holder">
                <b-button @click="requestXLS">
                  Выгрузить в xls
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-table
            hover
            responsive
            class="position-relative"
            :items="posts.items"
            :fields="fields"
            :sort-by.sync="options.sort.field"
            :sort-desc.sync="options.sort.desc"
            no-local-sorting
            show-empty
            empty-text="Нет ни одного видео за выбранный промежуток"
            @sort-changed="changeSort"
            @row-clicked="rowClick"
          >
            <template #cell(title)="data">
              <a :href="data.item.link" target="_blank">{{ data.item.title }}</a>
              <b-badge v-if="data.item.isRepost" pill variant="light-warning">Репост</b-badge>
            </template>
            <template #cell(publishedAt)="data">
              {{formatDate(data.item.publishedAt)}}
            </template>
            <template #cell(counters.totalReach)="data">
              {{formatNumber(data.item.counters.totalReach)}}
            </template>
            <template #cell(counters.subscriberReach)="data">
              {{formatNumber(data.item.counters.subscriberReach)}}
            </template>
            <template #cell(counters.viralReach)="data">
              {{formatNumber(data.item.counters.viralReach)}}
            </template>
            <template #cell(counters.advertisingReach)="data">
              {{formatNumber(data.item.counters.advertisingReach)}}
            </template>
            <template #cell(counters.likes)="data">
              {{formatNumber(data.item.counters.likes)}}
            </template>
            <template #cell(counters.reposts)="data">
              {{formatNumber(data.item.counters.reposts)}}
            </template>
            <template #cell(counters.comments)="data">
              {{formatNumber(data.item.counters.comments)}}
            </template>
            <template #cell(hasVideo)="data">
              <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  width="24px"
                  v-if="data.item.hasVideo"
              >
                <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
              </svg>
            </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0 px-1 pb-1">

          <!-- page length -->
          <b-form-group
            label="Кол-во на странице"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1 pagination-options"
          >
            <b-form-select
              id="perPageSelect"
              size="sm"
              inline
              :value="options.paging.itemsPerPage"
              :options="pageOptions"
              @change="changeItemsPerPage"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              first-number
              last-number
              :value="posts.paging.currentPage || 1"
              :total-rows="posts.paging.totalItems"
              :per-page="posts.paging.itemsPerPage"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>

    <b-modal
        id="reposts"
        title="Репосты"
        v-model="reposts.show"
        ok-only
    >
      <RepostsList
        :postId="reposts.postId"
      />
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BBadge, BContainer,
  BButton, BTable, BPagination,
  BModal, VBModal,
  BFormGroup, BFormSelect, BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import RepostsList from '@/views/pages/dashboard/components/GroupStats/PostsTab/RepostsList'
import Ripple from 'vue-ripple-directive'

import { formatDate, formatDuration, formatNumber } from '@/helpers/format'
import debounce from 'lodash.debounce'

export default {
  components: {
    BCard, BCardBody, BRow, BCol, BBadge, BContainer,
    BButton, BTable, BPagination,
    BModal, VBModal, BFormGroup, BFormSelect, BFormInput,
    BOverlay,
    RepostsList,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    posts: Object,
    options: Object,
    loading: Boolean,
  },

  data() {
    return {
      fields: [
        { key: 'title', label: 'Название поста', sortable: false },
        { key: 'publishedAt', label: 'Дата публикации', sortable: true },
        { key: 'counters.totalReach', label: 'Полный охват', tdClass: 'text-right', sortable: true },
        { key: 'counters.subscriberReach', label: 'Охват подписчиков', tdClass: 'text-right', sortable: true },
        { key: 'counters.viralReach', label: 'Виральный охват', tdClass: 'text-right', sortable: true },
        { key: 'counters.advertisingReach', label: 'Рекламный охват', tdClass: 'text-right', sortable: true },
        { key: 'counters.likes', label: 'Кол-во лайков', tdClass: 'text-right', sortable: true },
        { key: 'counters.reposts', label: 'Кол-во репостов', tdClass: 'text-right', sortable: true },
        { key: 'counters.comments', label: 'Кол-во комментариев', tdClass: 'text-right', sortable: true },
        { key: 'hasVideo', label: '', sortable: false },
      ],
      pageOptions: [5, 10, 15, 25, 50],
      reposts: {
        postId: 0,
        show: false,
      },
    }
  },

  methods: {
    formatDate: date => formatDate(date, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }),

    formatDuration,
    formatNumber,

    changeItemsPerPage(itemsPerPage) {
      this.$emit("request-posts", {
        ...this.options,
        paging: {
          ...this.options.paging,
          itemsPerPage,
        }
      })
    },

    changePage(page) {
      this.$emit("request-posts", {
        ...this.options,
        paging: {
          ...this.options.paging,
          page,
        }
      })
    },

    changeFilter: debounce(function (filter) {
      this.$emit("request-posts", {
        ...this.options,
        filter: {
          ...this.options.filter,
          title: filter,
        }
      })
    }, 500),

    changeSort(ctx) {
      let field = "publishedAt"
      switch (ctx.sortBy) {
        case "publishedAt":
          field = "publishedAt"
          break

        case "counters.totalReach":
          field = "totalReach"
          break

        case "counters.subscribersReach":
          field = "subscribersReach"
          break

        case "counters.viralReach":
          field = "viralReach"
          break

        case "counters.advertisingReach":
          field = "advertisingReach"
          break

        case "counters.likes":
          field = "numberOfLikes"
          break

        case "counters.reposts":
          field = "numberOfReposts"
          break

        case "counters.comments":
          field = "numberOfComments"
          break

      }

      this.$emit("request-posts", {
        ...this.options,
        sort: {
          ...this.options.sort,
          field,
          desc: ctx.sortDesc,
        }
      })
    },

    requestXLS() {
      this.$emit("download-xls")
    },

    rowClick(item, _, event) {
      event.stopPropagation()
      this.$store.dispatch("vk/getReposts", {
        postId: item.id,
        sort: {},
        paging: {},
      })
      this.reposts.postId = item.id
      this.reposts.show = true
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagination-options label {
    max-width: max-content;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .button-holder {
    justify-content: flex-end;

    @media (max-width: 580px) {
      justify-content: center !important;
      margin-top: 1rem;

      button {
        width: 100%;
      }
    }
  }
</style>
