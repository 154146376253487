<template>
  <b-col
      sm="12"
      md="12"
      lg="12"
      class="px-xl-2 mx-auto"
  >
    <b-row
        v-if="!this.$store.state.users.current.hasVKToken"
    >
      <b-col>
        <b-card>
          <h1 class="text-center">
            У вашего аккаунта нет VK токена, надо запросить его!
          </h1>
          <b-button
              variant="danger"
              block
              class="mt-1"
              @click="requestVKToken"
          >
            Запросить VK токен
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <h1>Введите ссылку на группу в ВК</h1>

      <validation-observer ref="selectForm">
        <b-form
            @submit.prevent="select"
        >
          <b-form-group
              label-for="search-link"
          >
            <validation-provider
                #default="{ errors }"
                name="Link"
                rules="required|vkURL"
                ref="linkInput"
            >
              <b-form-input
                  id="search-link"
                  v-model="link"
                  :state="errors.length > 0 ? false:null"
                  name="search-link"
                  placeholder="https://vk.com/club1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <b-button
              type="submit"
              variant="primary"
              block
          >
            Получить статистику
          </b-button>
        </b-form>
      </validation-observer>

      <b-button
          variant="danger"
          block
          class="mt-1"
          @click="logout"
      >
        Выйти
      </b-button>
    </b-card>
  </b-col>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {validatorUrlValidator} from '@core/utils/validations/validators'
import {required} from '@core/utils/validations/validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import getAccountConnectionURL from '@/api/users/getAccountConnectionURL'

export default {
  components: {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    from: String,
    to: String,
    searchError: String,
  },

  data() {
    return {
      link: "",
      required,
      vkURL,
    }
  },

  methods: {
    async select() {
      this.$refs.linkInput.setErrors([])

      const success = await this.$refs.selectForm.validate()
      if (!success) {
        return
      }

      let screenName = new URL(fixURL(this.link))
          .pathname
          .substring(1);

      this.$emit("select", screenName, this.from, this.to)
    },

    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      localStorage.removeItem('userData')

      this.$ability.update(initialAbility)

      this.$router.push({ name: 'auth-login' })
    },

    async requestVKToken() {
      location.href = await getAccountConnectionURL()
    },
  },

  watch: {
    searchError() {
      this.$refs.linkInput.setErrors([this.searchError])
    }
  },
}

const vkURL = extend("vkURL", {
  validate: val => {
    if (!validatorUrlValidator(val)) {
      return false
    }

    try {
      let url = (new URL(fixURL(val)))

      return url.hostname == "vk.com" && url.pathname.length > 1
    } catch {
      return false
    }
  },
  message: 'УРЛ должен быть до группы в ВК',
})

const fixURL = url => url.startsWith("http") ? url : "https://" + url
</script>
