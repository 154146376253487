<template>
  <b-card-group deck>
    <b-card v-for="card in cards" v-bind:key="card.title">
      <b-card-title>{{ card.title }}</b-card-title>
      <b-card-text>{{ card.value }}</b-card-text>
    </b-card>
  </b-card-group>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardGroup,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardGroup,
    BCardText,
  },

  props: {
    cards: Array,
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;

  @media (max-width: 580px) {
    flex-direction: row;
  }
}
</style>
