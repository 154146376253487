export const formatDate = (at, opts) => {
    const d = new Date(at)

    const dtf = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        ...(opts || {}),
    })

    return dtf.format(d)
}

export const formatDuration = duration => {
    let hours = Math.trunc(duration / (60 * 60))
    let minutes = Math.trunc((duration - hours * 60 * 60) / 60)
    let seconds = duration - hours * 60 * 60 - minutes * 60

    const addLeadingZero = v => v <= 9 ? `0${v}` : v

    hours = addLeadingZero(hours)
    minutes = addLeadingZero(minutes)
    seconds = addLeadingZero(seconds)

    return `${hours}:${minutes}:${seconds}`
}

const numFormat = new Intl.NumberFormat('ru-RU', { maximumSignificantDigits: 3 })

export const formatNumber = num => numFormat.format(num)
