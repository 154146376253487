<template>
  <b-container>
    <b-row>
      <b-link @click="$emit('back', $event)" href="#">< Назад</b-link>
    </b-row>

    <MainCard
        :title="groupStats.title"
        :description="groupStats.description"
        :link="groupStats.link"
        :photoURL="groupStats.photoURL"
    />
    <TotalStatsCard
        :members="groupStats.counters.members"
        :wallRecords="groupStats.counters.wallRecords"
        :videos="groupStats.counters.videos"
        :articles="groupStats.counters.articles"
        :loading="loadings.all"
    />

    <b-row class="mt-1 mb-1">
      <b-col>
        <b-tabs lazy class="stats-tabs" v-model="selectedTab">
          <b-tab title="Видео">
            <VideosTab
              :from="from"
              :to="to"
              :groupVideosStats="groupVideosStats"
              :loadings="{
                all: loadings.videos,
                videos: loadings.videosList,
              }"
              :uploads="uploads"
              :views="views"
              :videos="videos"
              :videosOptions="videosOptions"
              @from-changed="$emit('from-changed', $event)"
              @to-changed="$emit('to-changed', $event)"
              @request-videos="$emit('request-videos', $event)"
              @download-xls="$emit('download-videos-xls', $event)"
            />
          </b-tab>
          <b-tab title="Записи">
            <PostsTab
                :from="from"
                :to="to"
                :groupPostsStats="groupPostsStats"
                :loadings="{
                  all: loadings.posts,
                  videos: loadings.postsList,
                }"
                :publish="publish"
                :totalReach="totalReach"
                :posts="posts"
                :postsOptions="postsOptions"
                @from-changed="$emit('from-changed', $event)"
                @to-changed="$emit('to-changed', $event)"
                @request-posts="$emit('request-posts', $event)"
                @download-xls="$emit('download-posts-xls', $event)"
            />
          </b-tab>
          <template #tabs-end>
            <li class="d-flex filters align-items-baseline">
              <b-form-checkbox
                  switch
                  class="mr-1 only-with-video"
                  v-if="selectedTab === 1"
                  @change="$emit('only-with-video-changed', $event)"
              >
                Только с видео
              </b-form-checkbox>
              <div class="d-flex">
                <b-form-datepicker
                    :value="from"
                    locale="ru-RU"
                    :date-format-options="formatOptions"
                    label-close-button="Закрыть"
                    label-no-date-selected=""
                    label-help=""
                    :date-disabled-fn="fromDateDisabled"
                    start-weekday="1"
                    @input="fromChanged"
                    class="mr-1"
                    close-button
                />
                <b-form-datepicker
                    :value="to"
                    locale="ru-RU"
                    :date-format-options="formatOptions"
                    label-close-button="Закрыть"
                    label-no-date-selected=""
                    label-help=""
                    :date-disabled-fn="toDateDisabled"
                    start-weekday="1"
                    @input="toChanged"
                    close-button
                    right
                />
              </div>
            </li>
          </template>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MainCard from '@/views/pages/dashboard/components/GroupStats/MainCard'
import TotalStatsCard from '@/views/pages/dashboard/components/GroupStats/TotalStatsCard'
import VideosTab from '@/views/pages/dashboard/components/GroupStats/VideosTab'
import PostsTab from '@/views/pages/dashboard/components/GroupStats/PostsTab'
import {
  BCol,
  BContainer,
  BLink,
  BRow,
  BTabs,
  BTab,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BContainer,
    BRow,
    BLink,
    BTabs,
    BTab,
    BFormDatepicker,
    BFormCheckbox,
    MainCard,
    TotalStatsCard,
    VideosTab,
    PostsTab,
  },

  props: {
    from: String,
    to: String,
    groupStats: Object,
    loadings: Object,

    groupVideosStats: Object,
    uploads: Object,
    views: Object,
    videos: Object,
    videosOptions: Object,

    groupPostsStats: Object,
    publish: Object,
    totalReach: Object,
    posts: Object,
    postsOptions: Object,
  },

  data() {
    return {
      formatOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      selectedTab: 0,
    }
  },

  methods: {
    fromChanged(ymd) {
      this.$emit("from-changed", ymd)
    },
    toChanged(ymd) {
      this.$emit("to-changed", ymd)
    },
    fromDateDisabled(ymd, date) {
      return ymd > this.to
    },
    toDateDisabled(ymd, date) {
      return ymd < this.from
    },
  },
}
</script>

<style lang="scss">
.stats-tabs {
  width: 100%;

  .nav-tabs {
    align-items: baseline;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .filters {
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    max-height: 40px;

    .b-form-datepicker {
      max-width: 135px;
      min-width: 135px;
    }

    @media (max-width: 580px) {
      justify-content: center !important;
      margin-top: 1rem;
      margin-right: 0 !important;
      flex-direction: column;
      max-height: none;
      width: 100%;
      flex-wrap: wrap;
      align-content: center;
      align-items: center !important;
    }
  }

  .nav-tabs {
    @media (max-width: 580px) {
      display: flex;
      justify-content: center !important;
    }
  }

  .nav-link {
    font-size: 1.5rem;
  }

  .custom-switch {
    $color: #fa8c32;

    label {
      font-weight: bold;
      color: $color;
    }

    input:focus ~ label::before {
      border-color: $color !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }

    input:checked ~ label::before {
      border-color: $color !important;
      background-color: $color !important;
    }

    input:active ~ label::before {
      border-color: $color !important;
      background-color: $color !important;
    }
  }
}
</style>
