<template>
  <div>
    <TotalVideosStats
        :count="groupVideosStats.count"
        :views="groupVideosStats.views"
        :averageViews="groupVideosStats.averageViews"
        :likes="groupVideosStats.likes"
        :reposts="groupVideosStats.reposts"
        :comments="groupVideosStats.comments"
        :duration="groupVideosStats.duration"
        :averageDuration="groupVideosStats.averageDuration"
        :loading="loadings.all"
    />

    <Chart
        title="Загрузок"
        :data="uploads"
        :loading="loadings.all"
    />

    <Chart
        title="Просмотров"
        :data="views"
        :loading="loadings.all"
    />

    <VideosList
        :videos="videos"
        :videosOptions="videosOptions"
        @request-videos="$emit('request-videos', $event)"
        @download-xls="$emit('download-xls', $event)"
        :loading="loadings.all || loadings.videos"
    />
  </div>
</template>

<script>
import TotalVideosStats from '@/views/pages/dashboard/components/GroupStats/VideosTab/TotalVideosStats'
import Chart from '@/views/pages/dashboard/components/GroupStats/Chart'
import VideosList from '@/views/pages/dashboard/components/GroupStats/VideosTab/VideosList'

export default {
  components: {
    TotalVideosStats,
    Chart,
    VideosList,
  },

  props: {
    from: String,
    to: String,
    groupVideosStats: Object,
    loadings: Object,
    uploads: Object,
    views: Object,
    videos: Object,
    videosOptions: Object,
  },
}
</script>
