<template>
  <b-row class="d-block">
    <b-overlay :show="loading">
      <AdaptiveCardGroup :cards="[
          {
            title: 'Количество подписчиков',
            value: formatNumber(this.members),
          },
          {
            title: 'Количество записей',
            value: formatNumber(this.wallRecords),
          },
          {
            title: 'Количество видео',
            value: formatNumber(this.videos),
          },
          {
            title: 'Количество статей',
            value: formatNumber(this.articles),
          },
        ]"/>
    </b-overlay>
  </b-row>
</template>

<script>
import AdaptiveCardGroup from '@/views/pages/dashboard/components/GroupStats/AdaptiveCardGroup'
import {BRow, BOverlay} from 'bootstrap-vue'
import {formatNumber} from '@/helpers/format'

export default {
  components: {
    BRow,
    BOverlay,
    AdaptiveCardGroup
  },

  props: {
    members: Number,
    wallRecords: Number,
    videos: Number,
    articles: Number,
    loading: Boolean,
  },

  methods: {
    formatNumber,
  }
}
</script>
